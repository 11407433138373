.header {
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 0px 0px 32px 0px;
}

.header h1 {
  margin: 0px;
}

.newTeamButton {
  margin-left: auto;
}

.teamContainer {
  padding: 1rem;
}

.divider {
  margin: 16px -24px 0px;
  opacity: 0.2;
}

.compoundTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.teamSettingsContainer {
  border: 1px solid #a1a1aa;
  padding: 32px;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.manageTeams {
  h2 {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  h3 {
    margin-bottom: 0;
  }
}
