.joinRoom {
  h1 {
    margin-bottom: 17px;
  }

  p {
    margin-bottom: 35px;
  }
}

.centered {
  text-align: center;
}

.marginTop {
  margin-top: 14px;
}

.roomEmoji {
  display: inline-flex;
  margin-left: 5px;
  margin-right: 8px;
  background: rgb(233, 233, 233);
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20%;
}
