@import 'src/components/scss/colors.scss';

.avatar {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
  background: $grey-9;
  display: inline-block;
  z-index: 1;

  &.big {
    width: 180px;
    height: 180px;
  }

  &.fluid {
    width: 100%;
    height: 100%;
  }
}

.avatarImage {
  border-style: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 800px) {
  .avatar {
    width: 48px;
    height: 48px;
  }
}
