.logo {
  padding: 0px;
  height: 40px;
  width: 157px;
  box-sizing: border-box;
  border-radius: 5px;
  margin: auto 0;
  pointer-events: all;

  &.regular {
    height: 40px;
  }

  &.small {
    height: 24px;
  }

  &.medium {
    height: 34px;
  }
}

.logo img {
  height: 100%;
}