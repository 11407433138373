@import 'src/components/scss/colors.scss';
@import 'src/components/scss/typography.scss';

@mixin cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avatarContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: inline-block;
  z-index: 1;
  user-select: none;
  -webkit-user-select: none;

  &.circle {
    border-radius: 100%;
  }

  &.rectangle {
    border-radius: 8px;
  }

  .avatarImage {
    @include cover;
    z-index: 2;
    object-fit: cover;
  }

  video {
    object-fit: cover;
    z-index: 3;
  }

  canvas {
    z-index: 3; // TODO: RM-6604 - macOS avatars sometimes show on top of video canvas
  }
}

.avInfo {
  display: flex;
  position: absolute;
  bottom: 8px;
  left: 0px;
  width: 100%;
  column-gap: 5px;
  justify-content: center;
  z-index: 4;

  .avElement {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #ffffff;
  }
}

.avSpeaking {
  border-radius: 100%;
  border: 2px solid $speaking-orange;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
}

.fullNameContainer {
  @include cover;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 6;

  &:hover .fullName {
    visibility: visible;
  }

  .fullName {
    @include caption;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    color: white;
    overflow: hidden;
    padding: 8px;
    margin: 8px;
    text-overflow: ellipsis;
    visibility: hidden;
    white-space: nowrap;
    backdrop-filter: blur(8px);
  }
}
