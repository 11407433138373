.container {
  margin: auto;
  max-width: 420px;
  margin-top: 10vh;

  &.wide {
    max-width: 640px;
  }

  &.centerAligned {
    text-align: center;
  }
}

.header {
  font-size: clamp(30px, 34px, 36px);
  padding: 0px;
  margin: 0px;
  margin-bottom: 8px;
}

.subHeader {
  font-size: 16px;
}

.form {
  padding: 8px 0;
  width: 100%;
}

.form input:not([type='checkbox']):not([type='radio']) {
  margin: 8px 0px;
  width: 100%;
  height: 44px;
}

.form input[type='submit'] {
  margin-top: 12px;
  height: 52px;
}

.form .inputSet {
  padding-bottom: 16px;
}

.form .inputSet > label {
  font-weight: bold;
}

.form .inputRow {
  display: flex;
  flex-wrap: wrap;
}

.form .inputRow .checkboxLabel {
  margin-right: 16px;
}

.form .inputRow .checkboxPair {
  white-space: nowrap;
  display: inline;
}

.textFieldLabel {
  padding: 0px;
}

.button {
  width: 100%;
  margin: 8px 0px;
  height: 52px;
}

.decline {
  margin: 8px 0px;
}

.linkText {
  width: 100%;
  margin: 8px 0px;
  text-align: center;
}

.checkbox {
  margin: 8px 0px;
  width: 16px;
  height: 16px;
  vertical-align: baseline;
}

.checkboxLabel {
  margin-left: 4px;
}

.radio {
  margin: 8px 0px;

  width: 16px;
  height: 16px;
  vertical-align: baseline;
}

.loginFormDivider {
  width: 100%;
  padding: 16px 0px;
}

@media (max-width: 800px) {
  .container {
    // width: 90%;
    max-width: 420px;
  }
}

// DON'T USE THIS UNLESS ABSOLUTELY NECESSARY
.blink {
  animation: blink 1s steps(1, end) infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
