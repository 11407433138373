@import 'src/components/scss/colors.scss';
@import 'src/components/scss/typography.scss';
@import 'src/brand/Modal/Modal.module.scss';

.callControls {
  display: flex;
  column-gap: 12px;
  justify-content: center;
}

.callControl {
  background: $grey-8;
  user-select: none;
  -webkit-user-select: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 1px;

  width: 65px;
  height: 40px;

  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  .callControlLeft {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px;

    width: 40px;
    height: 40px;
  }

  .divider {
    background: rgba(0, 0, 0, 0.5);
    width: 1px;
    height: 40px;
  }

  img {
    cursor: pointer;
  }
}

.device {
  cursor: pointer;
  position: relative;
  padding: 12px 12px;
  min-height: 50px;

  font-size: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }

  .imagePlaceholder {
    width: 24px;
  }
}

.popup {
  padding: 0 !important;
}

.controlModal {
  &:global(.ui.modal) {
    @include popup;

    max-width: 600px;

    div.content {
      color: $grey-3;
      display: flex;
      flex-direction: column;
    }

    button {
      align-self: flex-end;
    }
  }
}
