@import 'src/components/scss/colors.scss';
@import 'src/components/scss/typography.scss';
@import 'src/components/scss/variable.scss';

.card {
  border: 2px solid $border-color-dark;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  margin-bottom: $card-margin-bottom;
}

%row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.joinTeamCard {
  @extend .card;

  h2 {
    text-align: left;
    border-bottom: 2px solid $border-color-light;
    padding-bottom: 16px;
  }

  .joinableTeamsList {
    margin-top: 32px;
    margin-bottom: 16px;

    max-height: 210px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .teamRow {
      @extend %row;
      margin-bottom: 30px;

      p.teamName {
        @include body-1;
        font-weight: 700;
        margin-bottom: 0;
      }

      p.teamDescription {
        @include body-2;
        color: $caption-color-dark;
      }
    }
  }

  .bottomText {
    color: $caption-color-dark;
    text-align: center;
    @include caption;
  }
}

.createTeamCard {
  @extend .card;
  @extend %row;
  cursor: pointer;

  h2 {
    @include subtitle-1;
    margin: 0;
  }
}

.newTeamForm {
  width: 100%;
  text-align: left;

  Button {
    margin-top: 32px;
  }

  .createTeamLabel {
    &:not(:first-of-type) {
      margin-top: 18px;
    }
  }

  .domainCaptureCheck {
    padding: 16px;
    margin-top: 32px;

    label {
      @include body-2;
    }
  }
}
