@import 'src/components/scss/colors.scss';
@import 'src/components/scss/typography.scss';

@mixin popup {
  background-color: $grey-9;
  border: 1px solid $grey-8;
  border-radius: 16px;
}

#notDimmed {
  /* hack to get the modal background to be transparent */
  background-color: rgba(0, 0, 0, 0);
}

.brandModal {
  &:global(.ui.modal) {
    @include popup;
    padding: 0;
    max-width: 600px;

    div.content {
      color: $grey-3;
      display: flex;
      flex-direction: column;
    }

    .buttons {
      align-self: flex-end;
      display: inline-flex;

      button {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
}
