@import '../../scss/typography.scss';
@import '../../scss/colors.scss';

.navbar {
  position: absolute;
  bottom: 0;
  width: 100%;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 24px 12px;
  box-sizing: border-box;
}

.mainNav {
  list-style-type: none;
  padding: 0px;
  margin: 0;
  padding-bottom: 16px;
}

.navLink {
  @include inter;
  font-size: 18px;
  text-transform: uppercase;
  color: $grey-5;
  text-decoration: none;
}

.mainNav li {
  margin: 20px 0px;
  text-align: left;
}

.navbarToggle {
  position: absolute;
  top: 36px;
  right: 24px;
  cursor: pointer;
  font-size: 24px;
}

@media screen and (min-width: 800px) {
  .navbar {
    height: 88px;
    max-width: 1248px;
    padding: 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    box-sizing: border-box;
  }

  .mainNav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: auto;
    padding-bottom: 0px;
  }

  .mainNav li {
    margin: 0;
  }

  .navLink {
    margin-left: 25px;
  }

  .navbarToggle {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .navLink {
    margin-left: 40px;
  }
}
