@import 'src/components/scss/colors.scss';
@import 'src/components/scss/typography.scss';
@import '../GuestCall.module.scss';
@import 'src/brand/Modal/Modal.module.scss';

.signUpLink {
  text-decoration: none;
  &:hover { text-decoration: none;}

  @media (max-width: $mobile) {
    display: none;
  }
}

.signUpPopup {
  &:global(.ui.inverted.popup) {
    @include popup;
    padding: 16px;
    min-width: 400px;

    @media (max-width: $mobile) {
      opacity: 0;
    }
  }

  h3 {
    @include headline-3;
  }

  .usersInCall {
    display: flex;
    align-items: center;
    background-color: $grey-8;
    border-radius: 8px;
    padding: 8px;
    gap: 8px;
    margin-bottom: 16px;
    flex: auto 1;
  }

  .joinUsersText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .userAvatarsContainer {
    white-space: nowrap;
  }

  .userAvatar {
    border-radius: 50%;
    border: 1px solid $grey-8;
    vertical-align: middle;
    position: relative;

    &:not(:first-child) {
      margin-left: -4px;
    }
  }
}
