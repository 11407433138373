@import 'src/components/scss/typography.scss';
@import 'src/components/scss/colors.scss';
@import '../Agora/Agora.module.scss';
@import './ZoomConstants.module.scss';

$IS_SPEAKING_BORDER_COLOR: #ff6635d1;
$participantFullnameBackground: rgba(0, 0, 0, 0.6);
$chatScrollbarBackground: rgba(0, 0, 0, 0.1);

$chat-width: 300px;

@mixin cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.screenshare {
  max-width: 100%;
  max-height: 100%;
  border: 2px solid $screenshare-green;
  border-radius: $screenshare-radius;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.remoteScreenshareCanvas {
  max-width: 100%;
  max-height: 100%;
}

.zoomVideoCanvasWrapper {
  width: 100%;
  height: 100%;
  min-width: $CANVAS_MIN_WIDTH;
  min-height: $CANVAS_MIN_HEIGHT;
  contain: paint;
}

.zoomVideoCanvasOverlay {
  position: fixed;
  height: 100%;
  width: 100%;
  display: grid;
  align-items: start;

  .participant {
    border-image-slice: 10;
    border-image-width: 10px;
    border-image-repeat: repeat repeat;
    // 16x9 border SVG with rounded corners
    border-image-source: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgd2lkdGg9IjEwMHB4IiBoZWlnaHQ9IjEwMHB4IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGQ9Ik0gMCAwIEwgMTAwIDAgTCAxMDAgMTAwIEwgMCAxMDAgWiBNIDIuNSAxMC41IEwgMi41IDg5LjUgQyAyLjUgOTMuOTE4IDYuMDgyIDk3LjUgMTAuNSA5Ny41IEwgODkuNSA5Ny41IEMgOTMuOTE4IDk3LjUgOTcuNSA5My45MTggOTcuNSA4OS41IEwgOTcuNSAxMC41IEMgOTcuNSA2LjA4MiA5My45MTggMi41IDg5LjUgMi41IEwgMTAuNSAyLjUgQyA2LjA4MiAyLjUgMi41IDYuMDgyIDIuNSAxMC41IFoiIHN0eWxlPSJmaWxsOiByZ2IoMjQsIDI0LCAyNyk7Ii8+Cjwvc3ZnPg==');
    border-style: solid;

    contain: paint; // Makes fixed positions w/in frame relative to participant

    display: flex;
    justify-content: center;

    & .showWhenVideoOff {
      max-width: 100%;
      max-height: 100%;

      &.defaultAvatarWrapper {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }

    & .avInfo {
      display: flex;
      position: absolute;
      bottom: 8px;
      left: 0px;
      width: 100%;
      column-gap: 5px;
      justify-content: center;
      margin-bottom: 5px;

      .avElement {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &.speaking {
      // 16x9 border SVG with outset 'red'-ish outline for "speaking ring"
      border-image-source: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgd2lkdGg9IjEwMHB4IiBoZWlnaHQ9IjEwMHB4IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOmJ4PSJodHRwczovL2JveHktc3ZnLmNvbSI+CiAgPHBhdGggZD0iTSAwIDAgTCAxMDAgMCBMIDEwMCAxMDAgTCAwIDEwMCBaIE0gMi41IDEwLjUgTCAyLjUgODkuNSBDIDIuNSA5My45MTggNi4wODIgOTcuNSAxMC41IDk3LjUgTCA4OS41IDk3LjUgQyA5My45MTggOTcuNSA5Ny41IDkzLjkxOCA5Ny41IDg5LjUgTCA5Ny41IDEwLjUgQyA5Ny41IDYuMDgyIDkzLjkxOCAyLjUgODkuNSAyLjUgTCAxMC41IDIuNSBDIDYuMDgyIDIuNSAyLjUgNi4wODIgMi41IDEwLjUgWiIgc3R5bGU9ImZpbGw6IHJnYigyNCwgMjQsIDI3KTsiLz4KICA8cmVjdCBzdHlsZT0iZmlsbDogbm9uZTsgc3Ryb2tlOiByZ2IoMjU1LCAxMDIsIDUzKTsgc3Ryb2tlLXdpZHRoOiAxcHg7IiB4PSIxLjUiIHk9IjEuNSIgaGVpZ2h0PSI5NyIgcng9IjkiIHJ5PSI5IiB3aWR0aD0iOTciIGJ4Om9yaWdpbj0iLTAuMDEwNDE3IC0wLjAyMDgzMyIvPgo8L3N2Zz4=');
    }

    &:hover .fullNameContainer {
      visibility: visible;
    }

    & .fullNameContainer {
      visibility: hidden;
      align-items: center;
      display: flex;
      justify-content: center;
      @include cover;
      z-index: 2;
    }

    .fullName {
      @include caption;
      background-color: $participantFullnameBackground;
      border-radius: 8px;
      color: white;
      overflow: hidden;
      padding: 8px;
      margin: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      backdrop-filter: blur(8px);
    }
  }
}

.remoteUserCanvas {
  position: relative;
  left: -39%;
}

.hidden {
  display: none;
}

.paginationElements {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-area: Pagination;

  &.alignRight {
    justify-content: end;
    padding-right: 24px;
    & .elementsWrapper {
      padding-left: 12px;
    }
  }

  &.hide {
    visibility: hidden;
  }

  & .elementsWrapper {
    width: $CANVAS_MIN_WIDTH;
    display: flex;
    justify-content: center;

    & .paginationElement {
      cursor: pointer;
    }
  }
}

.chatPanel {
  width: $chat-width;
  grid-area: Chat;
  background: rgba(29, 29, 29, 0.9);
  margin-right: 24px;
  padding: 16px;
  border: 1px solid $grey-8;
  border-radius: 16px;

  display: flex;
  flex-direction: column;

  & section.titlebar {
    & .chatTitle {
      @include subtitle-1;
      margin-bottom: 8px;
    }
  }

  //variables to calculate the max height of the messages section
  --topBar: 91px;
  --chatHead: 64px;
  --chatInput: 80px;
  --bottomBar: 88px;

  :global(section.messages) {
    overflow-y: scroll;
    flex-grow: 1;
    max-height: calc(
      100vh -
        (var(--topBar) + var(--chatHead) + var(--chatInput) + var(--bottomBar))
    );

    &::-webkit-scrollbar-track {
      background-color: $chatScrollbarBackground;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $call-yellow;
    }

    & .message {
      margin-bottom: 8px;

      & .fullname {
        color: $call-yellow;
        text-transform: uppercase;
        font-weight: 600px;
        margin-right: 8px;
      }

      & .timestamp {
        color: $grey-5;
        @include caption;
      }

      & .messageText {
        overflow-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }

  :global(section.input) {
    min-height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid $grey-8;
    padding-top: 8px;

    textarea {
      margin: 0;
      padding: 0 16px 0 0;
      width: 100%;
      background: none;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.2px;
      color: $white;
      outline-width: 0;
      flex-grow: 1;
      border: none;
      resize: none;

      &::-webkit-scrollbar-thumb {
        background-color: $call-yellow;
      }

      &::placeholder {
        opacity: 0.4;
      }
    }
  }
}
