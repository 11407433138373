@import './variable.scss';
@import './colors.scss';
@import './typography.scss';

// Override Sass min()
@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}

/* ------Content-------

# Container
# Buttons
# Inputs
# Misc
# Phone

---------------------*/

// ====== Container =======//
body {
  color: $body-color-dark;
  background: $background-color-dark;
  font-family: Inter;
}

h1 {
  @include headline-1;
}

h2 {
  @include headline-2;
}

h3 {
  @include headline-2;
  font-weight: 400;
  font-size: 22px;
}

a {
  color: $clickable-color-dark;
  padding: 0 0.25em;
  text-decoration: underline;
  text-underline-offset: 2.5px;
}

a:hover {
  color: $clickable-hover-color-dark;
  text-decoration: underline;
}

input {
  margin: 0 0.25em;
}

strong {
  font-weight: bold;
}

.AppMain {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
}

.AppFull {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0px 24px;
}

.AppContainer {
  display: flex;
  flex-direction: column;
  max-width: 848px;
  margin-left: auto;
  margin-right: auto;
  padding: 128px 24px;
  min-height: 100vh;
}

// ====== Buttons =======//
// DEPRECATED, use Button.tsx instead

%button-rounded-shared {
  cursor: pointer;
  font: inherit;
  background: inherit;
  border-radius: 2.5px;
  outline: none;
  font-style: normal;
  font-weight: bold;
  padding: 8px 16px;

  &.fluid {
    width: 100%;
  }
}

.primaryButton {
  @extend %button-rounded-shared;
  @include inter;
  background: $clickable-color;
  border: none;
  color: $white;

  &:hover {
    background: $clickable-hover-color;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.secondaryButton {
  @extend %button-rounded-shared;
  @include inter;
  border: 1px solid $clickable-color;
  color: $clickable-color;

  &.darkMode {
    color: $clickable-color-dark;
    border-color: $clickable-color-dark;
  }

  &:hover {
    color: $clickable-hover-light-color;
    border-color: $clickable-hover-light-color;

    &.darkMode {
      color: $clickable-hover-color-dark;
      border-color: $clickable-hover-color-dark;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.transparentButton {
  background-color: Transparent;
  border: none;
  color: $body-color-light;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  text-align: center;
  text-decoration-line: underline;
  text-underline-offset: 2.5px;
  font-size: 14px;

  &.undecorated {
    text-decoration-line: none;
  }

  &.darkMode {
    color: $clickable-color-dark;

    &:hover {
      color: $clickable-hover-color-dark;
    }
  }

  &:hover {
    color: $black;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.transparentButton--leftAligned {
  @extend .transparentButton;
  text-align: left;
  padding: 0;
}

.transparentButton--topMargin {
  @extend .transparentButton;
  margin-top: 6px;
  padding: 0;
}

.iconButton {
  color: $body-color-light;
  font-size: 14px;
  padding: 0px;
  background: none;
  border: none;

  &:disabled {
    opacity: 0.5;
  }
}

.primaryButton .iconButton {
  color: $white;
}

.mediumButton {
  font-size: 14px;
}

.borderless {
  border: none;
}

// ====== Inputs =======//

.inputPrimary {
  width: 100%;
  padding: 12px 16px;
  margin: 10px 0px;
  border-radius: 4px;
  font-size: 16px;
}

.inputSecondary {
  border: 1px solid $grey-4;
  border-radius: 4px;
  width: 100%;
  height: 36px;
  margin: 0px;
  padding: 8px 12px;
  background: none;
  color: $body-color-dark;
  outline: none;
  font-size: 14px;
}

.inputSecondary:focus {
  outline: 1px solid #e0e0e0;
}

.inputError {
  border-color: $red;
  color: $red;
}

.inputErrorMessage {
  color: $red;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 13px;
}

// ====== Select ===== //

.selectPrimary {
  display: block;
  width: 100%;
  padding: 12px 16px;
  margin: 10px 0px;
  border-radius: 4px;
  font-size: 16px;
  height: 44px;
}

// ====== Misc =======//

.app-divider {
  margin: 1rem (-$card-padding);
  opacity: 0.2;
}

// notification styles override
.Toastify__toast {
  border-radius: 4px;
  background-color: #333;
  padding: 0px;
}

.Toastify__toast-container {
  width: min(400px, 100vw);
}

.errorMessage {
  color: $error-color;
}

.desktopOnly {
  display: block;
}

.mobileOnly {
  display: none;
}

// ====== Semantic modal override =======//

.ui.modal > a {
  color: $body-color-light;
}

.ui.modal > .close {
  color: $body-color-light;
}

.hidden {
  display: none;
}

// ====== Phone =======//

@media (max-width: 1000px) {
  .AppContainer {
    padding-bottom: 3 * 8px;
  }
}

@media (max-width: 800px) {
  .AppContainer {
    padding-bottom: 5 * 8 * 8px;
  }
  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: block;
  }

  .dropdownButton {
    color: $body-color-light;
    font-size: 14px;
    margin: 8px 0px;
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.2s;
  }

  .dropdownButton:hover {
    transform: scale(1.1);
  }

  .app-divider {
    margin: 1rem (-$card-padding-mobile);
  }
}

.styleguide {
  .typography {
    .body-1 {
      @include body-1;
    }
    .body-2 {
      @include body-2;
    }
    .headline-1 {
      @include headline-1;
    }
    .headline-2 {
      @include headline-2;
    }
    .subtitle-1 {
      @include subtitle-1;
    }
    .subtitle-2 {
      @include subtitle-2;
    }
    .caption {
      @include caption;
    }
  }

  .button-sizes {
    button {
      margin-bottom: 8px;
    }
  }
  .colorblock {
    height: 32px;
    width: 32px;
    border: 1px solid $black;
    &.white {
      background: $white;
    }
    &.offwhite {
      background: $white-tinted;
    }
    &.black {
      background: $black;
    }
    &.red {
      background: $red;
    }
    &.red-tint {
      background: $red-tint;
    }
    &.green {
      background: $green;
    }
    &.green-tint {
      background: $green-tint;
    }
    &.blue {
      background: $blue;
    }
    &.blue-tint {
      background: $blue-tint;
    }
    &.pink {
      background: $pink;
    }
    &.pink-tint {
      background: $pink-tint;
    }
    &.grey-1 {
      background: $grey-1;
    }
    &.grey-2 {
      background: $grey-2;
    }
    &.grey-3 {
      background: $grey-3;
    }
    &.grey-4 {
      background: $grey-4;
    }
    &.grey-5 {
      background: $grey-5;
    }
    &.grey-6 {
      background: $grey-6;
    }
    &.grey-7 {
      background: $grey-7;
    }
    &.grey-8 {
      background: $grey-8;
    }
    &.grey-9 {
      background: $grey-9;
    }
    &.grey-10 {
      background: $grey-10;
    }
  }
}
