@import 'src/components/scss/colors.scss';

$screenshare-radius: 16px;

.videoPlayer {
  width: 140px;
  height: 140px;
  position: relative;
  overflow: hidden;
  border-radius: 100px;

  div {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    div {
      clip-path: inset(0% 0% 0% 0% round 10px);
    }
  }

  &.screenshare {
    overflow: inherit;
    width: 100%;
    height: 100%;
    border-radius: $screenshare-radius;

    video {
      border-radius: $screenshare-radius;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain !important;
    }
  }

  &.remoteScreenshare {
    border: 2px solid $screenshare-green;
    flex-grow: 1;
  }
}

.videoPlayer video {
  position: absolute;
  left: -70px;
  top: -70px;
  border-radius: 100px;
}

.expandToFitParent {
  width: 100%;
  height: 100%;
}
