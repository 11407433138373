// Brand Refresh 2023

$white: #fffdf8;
$white-tinted: #efede1;
$black: #000000;

$red: #ed4e1c;
$red-tint: #ee6d44;
$green: #8dc20f;
$green-tint: #a3da21;
$blue: #2163ff;
$blue-tint: #4a80ff;
$pink: #dc0ea2;
$pink-tint: #ee50c2;

/**
  Greys
**/
$grey-1: #fefefd;
$grey-2: #fdfafe;
$grey-3: #efede1;
$grey-4: #dadccf;
$grey-5: #aeb5aa;
$grey-6: #727a75;
$grey-7: #606664;
$grey-8: #4e5352;
$grey-9: #292b2c;
$grey-10: #161718;

/**
  Other colors
**/

$call-yellow: #ffb602;
$screenshare-green: #34c759;
$speaking-orange: #ff6635;

/**
  Semantic colors
  light and dark refer to light and dark mode, not the actual colors
**/

// background
$background-color-light: $white;
$background-color-dark: #111111;

// border
$border-color-light: $grey-3;
$border-color-dark: $grey-7;
$shadow: rgba(0, 0, 0, 0.25);

// link
$link-color: $blue;
$link-active-color: $blue-tint;

// success and error
$success-color: $green;
$error-color: $red;

// text
$header-color-light: $grey-10;
$header-color-dark: $grey-1;

$body-color-light: $black;
$body-color-dark: $white;

$caption-color-light: $grey-6;
$caption-color-dark: $grey-5;

/**
  Legacy styles backfill
**/

$clickable-color: $grey-10;
$clickable-hover-color: $blue-tint;
$clickable-hover-light-color: $grey-6;

$clickable-color-dark: $grey-1;
$clickable-hover-color-dark: $grey-3;
