@import 'src/components/scss/colors.scss';

.domainCapture {
  background-color: $grey-9;
  border-radius: 5px;
  padding: 16px 20px;
  font-size: 16px;
  margin-top: 32px;
  margin-bottom: 32px;

  label {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 10px;
    cursor: pointer;
  }

  input[type='checkbox'] {
    width: 25px;
    height: 19px;
    margin: 0;
  }
}
