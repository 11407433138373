@import 'src/components/scss/typography.scss';
@import 'src/components/scss/variable.scss';
@import 'src/components/scss/colors.scss';

// TODO: Raise up to variable?
$large-breakpoint: 900px;
$regular-padding: 32px;
$tiny-padding: 8px;

$content-padding-x: 104px;

// When viewport is < 420px tall media breakpoints
// change flex direction and hide the showcase
$short-window: 420px;

.setupPageContainer {
  display: flex;
  flex-direction: row-reverse;
  // RM-7613: should Sam decide to remove product screenshots, delete the showcase aside and all corresponding styles
  // change justify-content back to flex-end
  justify-content: center;
  align-items: center;

  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  @media (max-width: $large-breakpoint), (max-height: $short-window) {
    flex-direction: column;
  }
}

$showcase-radius: 12px;

.showcaseImage {
  display: flex;
  order: 2;
  // Image includes transparent padding
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  flex-shrink: 1;
  flex-basis: 688px;

  img {
    max-width: 100%; // Enables responsive sizing
    object-fit: scale-down; // Maintain aspect ratio
    max-height: calc(100vh - 2 * $navbar-height); // Not too tall!
    border-top-right-radius: $showcase-radius;
    border-bottom-right-radius: $showcase-radius;
  }

  // Hide the showcase when viewport height is very short
  @media (max-width: $large-breakpoint), (max-height: $short-window) {
    display: none;
  }
}

.mainContents {
  position: relative;
  display: flex;
  order: 1;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  // padding: $regular-padding $content-padding-x;
  // gap: $regular-padding;
  gap: 8px;
  // min-width: 512px;
  width: 100%;
  max-width: 460px;
  flex-basis: 768px;

  @media (max-width: 640px) {
    padding: 0px $regular-padding;
    padding-top: calc($navbar-height / 2);
  }

  // Protect viewport from breaking for extremely
  // "short" windows
  @media (max-height: 600px) {
    gap: $tiny-padding;
  }

  // Use the semantic 'section' tag to eliminate flex-gaps between items.
  & > section {
    width: 100%;

    // "Top-level" Buttons inside a section have a sensible margin applied to keep gaps
    & > button {
      margin-bottom: $tiny-padding;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .orDivider {
    margin: calc($tiny-padding * 2);
    display: flex;
    justify-content: center;
  }

  h1 {
    // Don't let container "shrink" below content size.
    min-height: auto;
    text-align: center;

    &.marginAfter {
      margin-bottom: 24px;
    }
  }

  h2 {
    @include body-1;
    font-weight: 700;
    margin-block-end: 8px;
  }

  p {
    @include body-1;
    align-self: flex-start;

    &.caption {
      color: $caption-color-dark;
      text-align: center;
    }

    &.centered {
      align-self: center;
      text-align: center;
    }
  }

  .terms_of_service {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      text-align: center;
      font-size: 13px;
      color: white;
      opacity: 0.3;

      a {
        padding: 0;
      }
    }
  }

  .redirect {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    text-align: right;
    display: flex;
    justify-content: center;
    pointer-events: none;

    div {
      width: 100%;
      padding-top: 32px;
      padding-right: 24px;
      max-width: 1200px;

      p {
        pointer-events: all;
        opacity: 0.5;
        font-size: 15px;

        a {
          padding: 0;
        }
      }
    }

    @media (max-width: 900px) {
      div {
        padding-top: 26px;
      }
    }

    @media (max-width: 640px) {
      div {
        padding-top: 26px;
      }

      p {
        font-size: 13px;
        width: 100%;
      }
    }

    @media (max-width: 375px) {
      span {
        visibility: hidden;
      }
    }
  }

  a {
    text-decoration: underline;
    text-underline-offset: 2.5px;
    color: inherit;
  }

  // Allow raw 'caption' class to enable subtitle styling within SetupPage
  :global(.caption) {
    @include subtitle-1;
    font-weight: 400;
  }

  // Select semantic UI's Form.Field element
  div:global(.field) {
    width: 100%;

    label {
      @include label;
      font-size: 13px;
    }

    input {
      border-radius: 8px;
    }
  }

  // Semantic UI is incredibly aggressive about applying
  // input+action styles, so the following *must* be as
  // highly specific *AND* !important to take effect
  :global(.ui.input.fluid:not([class*='left action'])) {
    width: 100%;

    & > input {
      border-top-right-radius: 0.285714rem !important;
      border-bottom-right-radius: 0.285714rem !important;
      border-right-color: rgba(34, 36, 38, 0.15) !important;
    }

    button {
      margin-left: 16px;
    }
  }
}

.recaptcha {
  position: absolute;
}

.errorMessage {
  font-size: 13px;
  color: red;
  text-align: center;
  margin-bottom: 10px;

  a {
    color: red;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2.5px;
    padding: 0;
  }
}

.inviteLinkWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  & button {
    min-width: 95px;
    height: 40px;
    margin-left: 10px;
  }
}

.inviteWaitlistUsers {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  margin: 20px 0;

  & > .waitlistUser {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;

    & > input {
      width: 16px;
      height: 16px;
      border: 2px solid $black;
      border-radius: 4px;
      margin-right: 10px;
    }
  }
}

.inviteContent {
  & hr {
    border-color: $grey-10;
    margin: 5 * 8px 0;
  }

  & .mobileInputCaption {
    margin: 0;
  }

  & .macOS12OrHigher {
    color: $grey-10;
    margin-top: 5 * 8px;
  }
}

.avatarWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  img,
  canvas {
    width: 96px;
    height: 96px;
    border-radius: 48px;
  }

  canvas {
    display: none;
  }
}

.emailSentSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  p {
    text-align: center;
  }

  a {
    cursor: pointer;
  }
}

.macos_version_note {
  color: #fff;
  text-align: center;
  font-family: Manrope;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
  opacity: 0.3;
}
