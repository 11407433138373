@import 'components/scss/typography.scss';
@import 'components/scss/colors.scss';
@import 'src/brand/Modal/Modal.module.scss';

$border-radius: 8px;
$spacing: 8px;
$text-light-mode: $grey-10;
$content-padding: 16px;

.inCallFeedback {
  &:global(.ui.inverted.popup) {
    @include popup;
    padding: $content-padding;
    min-width: 400px;
  }
}

.postCallFeedback {
  &:global(.ui.modal) {
    border-radius: 16px;
    padding: $content-padding;
    max-width: 400px;
  }
}

$container-height: 56px;

.postCallFeedbackEmpty {
  height: $container-height;
}

.postCallFeedbackContainer {
  @include body-2;
  align-items: center;
  border-radius: $border-radius;
  display: grid;
  gap: $spacing;
  grid-template-columns: minmax(max-content, 1fr) min-content min-content;
  padding: $spacing;
  padding-left: $spacing * 2;
  height: $container-height;
  width: 100%;

  .submitAgain {
    @include body-2;
    cursor: pointer;
    border: none;
    background-color: inherit;
    text-decoration: underline;
    text-underline-offset: 2.5px;
    white-space: nowrap;
  }
}

.callFeedbackContent {
  h3 {
    @include headline-3;
    color: $text-light-mode;
  }

  .buttonContainer {
    display: grid;
    gap: $spacing;

    &.topics {
      grid: 1fr 1fr / 1fr 1fr;
    }

    .back {
      margin-top: 16px;
      margin-right: auto;
    }
  }

  button {
    @include body-2;
    white-space: nowrap;
    text-align: left;

    &.topic {
      border: 1px solid;
      border-radius: $border-radius;
      color: $text-light-mode;
      background-color: $grey-2;
      border-color: $grey-3;
      padding: 16px;

      &:hover,
      &:active {
        background-color: $grey-3;
        border-color: $grey-4;
      }
    }
  }

  &.darkMode {
    h3 {
      color: white;
    }

    button.topic {
      color: white;
      background-color: $grey-8;
      border-color: $grey-7;

      &:hover,
      &:active {
        background-color: $grey-7;
        border-color: $grey-6;
      }
    }
  }
}

.callFeedbackDetails {
  h3 {
    @include headline-3;
    color: $text-light-mode;
  }

  .subtopic {
    @include body-2;
    margin-bottom: $spacing;
  }

  .textarea {
    @include body-2;
    padding: 10px 16px;
    width: 100%;
    border: 1px solid;
    border-color: $grey-4;
    background-color: $grey-2;
    color: $text-light-mode;
    border-radius: $border-radius;
    resize: none;
    outline: none;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    button {
      text-align: left;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &.darkMode {
    h3 {
      color: white;
    }

    .textarea {
      color: white;
      border-color: $grey-7;
      background-color: $grey-8;
    }
  }
}
