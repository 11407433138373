@import 'src/components/scss/colors.scss';

.header {
  padding-bottom: 20px;
  width: 100%;
  text-align: center;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 56px;
    line-height: 120%;
    margin-bottom: 8px;
  }

  p {
    color: $caption-color-dark;
    font-size: 15px;
    width: 100%;
    max-width: 640px;
    padding: 0px 40px;
  }

  a {
    color: $caption-color-dark;
  }

  @media (max-width: 640px) {
    h1 {
      font-size: 44px;
    }

    p {
      font-size: 13px;
      padding: 0px;
    }
  }

  @media (max-width: 375px) {
    h1 {
      font-size: 36px;
    }
  }

  .icon {
    position: relative;
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 900px) {
      width: 120px;
      height: 120px;
    }

    @media (max-width: 640px) {
      width: 80px;
      height: 80px;
    }

    img {
      &.multiIcon {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

      &.blobs {
        width: 50vw;
        height: 30vh;
        max-width: 1600px;
        max-height: 1200px;
        min-width: 800px;
        min-height: 600px;
        z-index: -99; // this is so the blobs overflowing the header do not interrupt any other content
      }
    }
  }
}
