@import 'src/components/scss/colors.scss';

.selfieUpdater {
  width: 225px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  .photoContainer {
    position: relative;
    display: inline-flex;
    margin-bottom: 22px;

    .photoLoader {
      position: absolute;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 100%;
      color: #fff;
      font-size: 40px;
      font-weight: bold;
    }
  }

  .buttonGroup {
    display: flex;

    & > *:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: 1px;
    }

    & > *:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 6px;
      padding-right: 6px;
      display: flex;
      align-items: center;
    }
  }

  label {
    cursor: pointer;
  }

  input {
    display: none;
  }

  .buttonsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    & > * {
      margin: 0 2px 0 2px;
    }
  }

  .uploadImage {
    color: $caption-color-dark;
    margin: auto;
    text-align: center;
  }

  .error {
    font-size: 12px;
    color: red;
    text-align: center;
    margin-bottom: 8px;
  }

  .webcamContainer {
    margin-left: -30px;

    video {
      object-fit: cover;
    }
  }

  .downCaret {
    transform: rotate(90deg);
    font-size: 20px;
  }
}

.selfieUpdaterContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.progressBar {
  background-color: #000;
  height: 6px;
  width: 100%;
  margin-bottom: 10px;

  .progress {
    background-color: red;
    height: 6px;
  }
}

.videoDevice {
  cursor: pointer;
  position: relative;
  padding: 2px 5px 2px 5px;
  font-size: 13px;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }

  &.selected::before {
    position: absolute;
    content: '';
    top: 7px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: green;
  }
}
