@import 'src/components/scss/colors.scss';

.avatarPicker {
  padding: 40px;
  position: relative;
  background-color: $background-color-dark;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 20px;
    margin-bottom: 56px;
  }

  button {
    width: 410px;
    max-width: 100%;
    font-size: 13px;
    font-weight: bold;
  }

  .closeIcon {
    position: absolute;
    right: 50px;
    top: 50px;
    cursor: pointer;
  }
}

.avatarList {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  & > .avatar {
    margin-right: 35px;
    margin-bottom: 20px;
  }

  .break {
    flex-basis: 100%;
  }
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  z-index: 3;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &.active {
    border: 3px solid $grey-3;
  }

  img {
    border-style: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.chooseAvatarBtnWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
