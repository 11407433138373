@import 'src/components/scss/colors.scss';

.titles {
  margin-top: 50px;
  text-align: center;
}

$subHeaderFontSize: 20px;

.titles .subHeader {
  font-size: $subHeaderFontSize;
}

.subHeader a {
  color: $caption-color-dark;
  text-decoration: underline;
  text-underline-offset: 2.5px;
}

.downloadImage {
  padding-top: 32px;
  max-width: 600px;
}
