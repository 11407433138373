@import 'src/components/scss/colors.scss';
@import 'src/components/scss/typography.scss';
@import '../GuestCall.module.scss';
@import 'src/brand/Modal/Modal.module.scss';
@import './Zoom/ZoomConstants.module.scss';

$incall-padding: 24px;
$navbar-height: 88px;
$column-gap: 16px;
$thumbnail-height: 40px;

// must match MIN_WIDTH in AvatarGrid component
// for the min avatar width in Agora calls
$min-width: 160px;

.inCall {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-auto-rows: min-content;
  grid-template-columns: auto;
  grid-auto-columns: min-content;

  grid-template-areas:
    'TopBar'
    'Elements'
    'Pagination'
    'BottomBar';

  &.chatOpen {
    grid-template-areas:
      'TopBar TopBar'
      'Elements Chat'
      'Pagination Chat'
      'BottomBar BottomBar';
  }

  .videoElementsContainer {
    width: 100%;
    height: 100%;
    padding: $incall-padding;
    overflow: hidden;
    grid-area: Elements;

    &.screenshare {
      display: flex;
      column-gap: $incall-padding;
    }
  }

  .avatarsContainer {
    width: 100%;
    height: 100%;
    min-width: $min-width;
    overflow: hidden;
    flex-basis: $CANVAS_MIN_WIDTH;
  }
}

.topBar {
  padding: $incall-padding;
  min-height: $navbar-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: TopBar;

  .topBarSection {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  .convoName {
    color: white;
    font-size: 16px;
    font-weight: 700;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  .convoUsers {
    color: $grey-4;
    font-size: 14px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .screensharingUser {
    display: flex;
    align-items: center;
    column-gap: 8px;
    height: auto;
    width: max-content;
    background: $screenshare-green;
    border-radius: 8px;
    padding: 10px 16px;
    white-space: nowrap;
  }
}

.bottomBar {
  padding: $incall-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: $navbar-height;
  column-gap: $column-gap;
  grid-area: BottomBar;
}

.callControlsContainer {
  display: flex;
  column-gap: $column-gap;
  height: 100%;

  & .divider {
    height: 40px;
    width: 1px;
    background-color: $grey-6;
  }
}

.hangUpButton {
  background: linear-gradient(180deg, $red 0%, #fa5858 100%);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  padding: 0 10px;
  width: 50px;

  .phoneIcon {
    vertical-align: middle;
  }
}

.zoomLocalScreenshare {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.musicContainer {
  display: flex;
  align-items: center;
  column-gap: $column-gap;

  @media (max-width: $mobile) {
    display: none;
  }

  button.playPause {
    padding: 8px 12px;
  }
}

.thumbnailContainer {
  height: $thumbnail-height;
  position: relative;

  .spotifyIcon {
    position: relative;
    right: 10px;
  }

  .userSharingAvatar {
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    border: solid 3px $background-color-dark;
  }

}

.musicInfo {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  color: $grey-5;

  @media (max-width: 700px) {
    display: none;
  }

  .userSharingName {
    font-size: 14px;
    font-weight: 700;
    color: $grey-1;
  }
}

.volumeControl {
  display: flex;
  column-gap: 8px;
  position: relative;
  top: 3px;

  .volumeSlider {
    input[type='range'] {
      max-width: 48px;
    }
  }
}
