@import 'src/components/scss/typography.scss';
@import 'src/components/scss/variable.scss';
@import 'src/components/scss/colors.scss';

// Wraps entire GuestCallSetup / AVSetup page content
.guestCallSetup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - $navbar-height);
  position: relative;
  top: $navbar-height;
}

// "Multi is..." caption at bottom of page.
.caption {
  @include body-2;
  color: $grey-5;
  text-align: center;
  max-width: 580px;
  margin-bottom: 32px;

  a {
    color: $grey-5;
    text-decoration: underline;
    text-underline-offset: 2.5px;
  }
}

@mixin columnar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  gap: 16px;
}

// PreCall step
.oneColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 16px;
  max-width: 480px;

  > div {
    @include columnar;
    width: 100%;
  }
}

.mainContentCard {
  border: 1px solid $grey-1;
  border-radius: 4px;
  padding: 24px;
  text-align: center;

  > p {
    margin-bottom: 24px;
  }
}

// AVSetup step
.splitColumns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  margin: 16px;

  > div {
    @include columnar;
  }
  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.rightContainer {
  width: 300px;
}

.cameraContainerRectangular {
  position: relative;
  width: 369px;
  height: 208px;
  margin-bottom: 16px;
  border-radius: 8px;

  &.speakingBorder {
    outline: 2px solid $speaking-orange;
    -webkit-box-shadow: 0 0 0 2px $speaking-orange;
  }

  video {
    height: 208px;
    width: 369px;
  }
}

.termsOfService {
  @include caption;
  text-align: center;
  color: $caption-color-dark;
  margin-top: 16px;

  a {
    color: $caption-color-dark;

    &:hover {
      text-decoration: underline;
    }
  }
}

.nameInput {
  @include body-2;
  margin: 0;
  margin-bottom: 16px;
  width: 100%;
  border-radius: 2.5px;
  padding: 8px 16px;
  color: $white;
  border: 1px solid $grey-7;
  background-color: $grey-8;
  outline: none;
}

.buttonAppleLogo {
  filter: invert(1); // Swap White apple Logo into Black
  margin-right: 4px;
}

.guestCallNotAllowedContent {
  margin: 10px 0px;
  text-align: center;
}

.signupCard {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  color: $grey-5;
}

.signupCardTitle {
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
}

.signupCardSubtitle {
  font-weight: 200;
  font-size: 13px;
}

.signupCardLink {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  & .signupCardButton {
    font-weight: 500;
    font-size: 16px;
    height: 40px;
    margin-right: -12px;
  }
}
