// must match GAP in AvatarGrid component
$grid-gap: 20px;

.gridContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  gap: $grid-gap;

  &.verticalNav {
    flex-direction: column;
    justify-content: flex-start;

    .avatarGrid {
      justify-content: flex-start;
      flex-direction: column;
    }
  }
}

.pageNavigation {
  width: 28px;
  height: 28px;
  text-align: center;

  & > * {
    cursor: pointer;
  }
}

.avatarGrid {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: $grid-gap;
  flex-wrap: wrap;
  overflow: hidden;
}

.avatarGridHidden {
  position: absolute;
  visibility: collapse;
}
