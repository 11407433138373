@import 'src/components/scss/colors.scss';
@import 'src/components/scss/typography.scss';

.input {
  background-color: inherit;
  border-radius: 10px;
  @include body-1;
  height: 40px;
  margin: 10px 0;
  padding: 0 10px;

  &::placeholder {
    color: $grey-6;
  }

  &:focus {
    outline: solid $white;
  }

  &.darkMode {
    background-color: $grey-9;
    border: 1px solid $grey-7;
    color: $body-color-dark;
  }

  &.fluid {
    width: 100%;
  }

  &.ellipsisOverflow {
    text-overflow: ellipsis;
  }
}
