@import 'src/components/scss/colors.scss';
@import 'src/components/scss/typography.scss';

div.flexContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;

  padding-top: 100px;
  height: 100vh;

  img {
    max-width: 300px;
    margin-bottom: 32px;
  }

  h1 {
    margin-bottom: 24px;
    max-width: 600px;
  }

  p {
    @include body-1;
    margin-bottom: 32px;
    max-width: 600px;
  }

  .error {
    color: $error-color;
  }

  button {
    margin-bottom: 16px;
    width: 232px;
    display: block;
    margin: 16px auto;
  }
}
