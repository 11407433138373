@import 'src/components/scss/colors.scss';

.alert {
  padding: 32px;
  background-color: $background-color-dark;
  color: $body-color-dark;
}

.alertButtons {
  display: flex;
  justify-content: flex-end;
}

.alertButtons > button {
  margin-top: 24px;
  margin-left: 16px;
}

.header {
  font-size: 20px;
  font-weight: 700;
  padding: 0px;
  margin: 0px;
}

.message {
  font-size: 14px;
  line-height: 20px;
  padding: 0px;
  margin: 8px 0px 0px 0px;
}

.alert input:not([type='checkbox']) {
  margin-top: 24px;
  font-size: 14px;
}

.checkbox {
  margin-top: 16px;
  margin-left: 0px;
  width: 16px;
  height: 16px;
  vertical-align: baseline;
}

.checkboxLabel {
  margin-left: 4px;
}

.removeUserButton {
  border-radius: 4px;
  border: 1px solid #ff7171;
  color: #ff7171;
  font-size: 14px;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
  display: flex;
  column-gap: 13px;
  align-items: center;
}
