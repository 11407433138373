.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header h3 {
  margin-right: auto;
}

.calendarRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.calendarLabel {
  display: flex;
  align-items: center;
  flex: 5;
}

.calendarSpacer {
  flex: 1;
}

@media (max-width: 800px) {
  .calendarRow {
    flex-direction: column;
  }

  .signInButton {
    margin-top: 16px;
  }
}
