@mixin avatar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.1);
}

.defaultAvatar {
  @include avatar;
  z-index: -1;
  font-size: 18px;

  img {
    width: 50%;
  }
  &.noZIndex {
    z-index: auto;
  }

  &.clipCorners {
    clip-path: inset(0 round 5px);
  }
}

.initialsAvatar {
  @include avatar;
  text-transform: uppercase;
  font-size: clamp(24px, 4vmin, 48px);
  font-weight: 500;
}
