@import 'src/components/scss/colors.scss';
@import 'src/brand/Modal/Modal.module.scss';

// used by other modules in GuestCall
$mobile: 480px;

.mainContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $background-color-dark;
  color: white;
  overflow-y: auto;

  & .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
