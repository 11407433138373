@import 'components/scss/typography.scss';

.guestCallEnded {
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 100px 24px;
  gap: 160px;

  @media (max-width: 1300px) {
    gap: 60px;
  }

  @media (max-width: 1100px) {
    padding: 100px;
    flex-flow: column-reverse;
    min-height: auto;
    height: 100vh;
  }

  .imageContainer {
    img {
      width: 100%;
      border: solid 3px black;
      border-radius: 16px;
      max-height: 600px;
    }
  }
}

.guestCallEndRedirect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-top: 50px;
    margin-bottom: 16px;
    word-break: break-all;
  }

  p {
    @include body-1;
    margin-bottom: 32px;
  }

  .actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
