// --- font family ---
@font-face {
  font-family: 'Inter';
  src: url(https://updates.multi.app/assets/fonts/Inter-VariableFont.ttf)
    format('truetype');
}

@font-face {
  font-family: 'PPEditorial';
  src: url(https://updates.multi.app/assets/fonts/PPEditorialNew-Regular.woff2)
    format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'PPEditorial';
  src: url(https://updates.multi.app/assets/fonts/PPEditorialNew-Bold.woff2)
    format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'PPEditorial';
  src: url(https://updates.multi.app/assets/fonts/PPEditorialNew-Italic.woff2)
    format('woff2');
  font-style: italic;
}

@font-face {
  font-family: 'PPEditorial';
  src: url(https://updates.multi.app/assets/fonts/PPEditorialNew-BoldItalic.woff2)
    format('woff2');
  font-weight: 700;
  font-style: italic;
}

// --- font styles ---

@mixin inter {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
}

@mixin editorial {
  font-family: 'PPEditorial';
  font-weight: 400;
  font-style: normal;
}

@mixin body-1 {
  @include inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin body-2 {
  @include inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

@mixin headline-1 {
  @include editorial;
  font-size: 55px;
  line-height: 110%;
}

@mixin headline-2 {
  @include inter;
  font-weight: 300;
  font-size: 28px;
  line-height: 34px;
}

@mixin headline-3 {
  @include inter;
  font-size: 18px;
  font-size: 18px;
  line-height: 150%;
}
@mixin subtitle-1 {
  @include inter;
  font-size: 18px;
  line-height: 150%;
}

@mixin subtitle-2 {
  @include inter;
  font-size: 14px;
  line-height: 150%;
}

@mixin label {
  font-size: 14px;
  line-height: 150%;
}

@mixin button-small {
  @include subtitle-2;
  font-weight: 600;
}

@mixin button-medium {
  @include subtitle-2;
  font-weight: 600;
}

@mixin button-large {
  @include subtitle-1;
  font-weight: 600;
}

@mixin button-x-large {
  @include subtitle-1;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

@mixin caption {
  @include inter;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

:global(.centered) {
  text-align: center;
}
