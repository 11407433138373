@import '../../scss/colors.scss';

.teamUser {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.avatar {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  overflow: hidden;
}

.name {
  font-size: 14px;
  margin-bottom: 4px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 16px;
  margin-right: auto;
}

.pending {
  color: change-color($color: $body-color-light, $alpha: 0.75);
  margin-bottom: 4px;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: baseline;
  column-gap: 20px;
}

@media (max-width: 800px) {
  .email {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 400px) {
  .email {
    max-width: 150px;
  }
}
