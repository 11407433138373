@import '../scss/colors.scss';

.container {
  padding: 20px 16px;
  background: $background-color-light;
  width: 100%;
  border-left: solid 4px $success-color;
}

.containerSuccess {
  border-color: $success-color;
}

.containerFailure {
  border-color: $error-color;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-family: Inter;
}

.message {
  padding: 0px 16px;
  margin: 0px;
  color: $body-color-light;
}

.closeButton {
  margin-left: auto;
}

.closeButton:hover {
  color: $body-color-light;
}
