@import 'src/components/scss/colors.scss';
@import 'components/scss/typography.scss';
@import '../GuestCall.module.scss';
@import 'src/brand/Modal/Modal.module.scss';

.screensharingPopup {
  &:global(.ui.inverted.popup) {
    @include popup;
    padding: 24px;
    min-width: 400px;

    @media (max-width: $mobile) {
      opacity: 0;
    }
  }

  &.hidden {
    visibility: hidden !important;
  }

  h3 {
    @include subtitle-1;
    margin: 0;
  }

  div.content {
    display: flex;
    flex-direction: column;
  }

  :global(.agoraMediaPlayer) {
    height: 200px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
  }

  .buttonRow {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    gap: 10px;

    button {
      flex: 1;
    }
  }

  .hidePopupButton {
    vertical-align: middle;
  }
}

.screenshareButton {
  @media (max-width: $mobile) {
    display: none;
  }
}

.screenshareButtonIcon {
  vertical-align: middle;
}
