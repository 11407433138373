@import '../../scss/colors.scss';
@import '../../scss/typography.scss';

.navbar {
  @include inter;
  background-color: $background-color-light;
  width: 100%;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 24px 12px;
  position: fixed;
  padding-top: 24px;
  pointer-events: none;

  & li {
    pointer-events: all;
  }

  &.transparent {
    background-color: transparent;

    @media (max-width: 900px) {
      background-color: $background-color-light;

      &.transparent {
        background-color: transparent;
      }
    }
  }

  &.darkMode {
    background-color: $background-color-dark;

    &.transparent {
      background-color: transparent;
    }

    & .navLink {
      color: $clickable-color-dark;

      &:hover {
        color: $clickable-hover-color-dark;
      }
    }
  }
}

.mainNav {
  list-style-type: none;
  display: none;
  padding: 0px;
  margin: 0px;
  padding-bottom: 28px;
}

.navLink {
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  text-underline-offset: 11px;
}

.activeLink {
  color: $blue;
  text-decoration: underline;
}

.mainNav li {
  margin: 28px 0px 0px;
  text-align: left;
}

.navbarToggle {
  position: absolute;
  top: 36px;
  right: 24px;
  cursor: pointer;
  color: $body-color-light;
  font-size: 24px;
  pointer-events: all;
}

.active {
  display: block;
}

@media screen and (min-width: 900px) {
  .navbar {
    height: 88px;
    max-width: 1248px;
    padding: 0px 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }

  .mainNav {
    display: flex;
    margin-right: 30px;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 0px;
  }

  .mainNav li {
    margin: 0;
  }

  .navLink {
    margin-left: 25px;
  }

  .navbarToggle {
    display: none;
  }
}

@media screen and (min-width: 1100px) {
  .navLink {
    margin-left: 40px;
  }
}
