.flexContainer {
  display: flex;
  margin-top: 20px;
}

.linkInput {
  flex: 1;

  input {
    margin-top: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

button.copy {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

i.copyIcon {
  height: auto;
  margin-right: 0;
}
