@import 'src/components/scss/colors.scss';
@import '../GuestCall.module.scss';
@import 'src/brand/Modal/Modal.module.scss';

.listenButton {
  @media (max-width: $mobile) {
    display: none;
  }
}

.listenModal {
  &:global(.ui.modal) {
    @include popup;

    > :global(.content) {
      padding: 0;
    }
  }

  img {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    width: 50%;

    // breakpoint follows semantic ui
    @media (max-width: 767px) {
      display: none;
    }
  }

  .content {
    padding: 64px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      color: $header-color-dark;
      margin: 24px 0 16px 0;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: $grey-4;
      margin-bottom: 32px;
    }
  }
}
