.inviteLinkError {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30vh;

  a {
    text-decoration: none;
  }
}
