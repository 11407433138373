@import 'src/components/scss/colors.scss';
@import 'src/components/scss/typography.scss';

$light-color: $grey-1;
$dark-color: $grey-10;

$light-color-hover: $grey-2;
$dark-color-hover: $grey-9;

$p-vertical-small: 5.5px;
$p-vertical-medium: 9.5px;
$p-vertical-large: 16px;
$p-vertical-x-large: 20px;

$p-horizontal-small: 12px;
$p-horizontal-medium: 14.25px;
$p-horizontal-large: 24px;
$p-horizontal-x-large: 24px;

.button {
  background-color: inherit;
  border-radius: 8px;
  height: 48px;
  cursor: pointer;
  outline: none;
  text-align: center;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  /* size */

  &.small {
    @include button-small;
    padding: $p-vertical-small $p-horizontal-small;
    gap: 4px;
  }

  &.medium {
    @include button-medium;
    padding: $p-vertical-medium $p-horizontal-medium;
  }

  &.large {
    @include button-large;
    padding: $p-vertical-large $p-horizontal-large;
  }

  &.x-large {
    @include button-x-large;
    padding: $p-vertical-x-large $p-horizontal-x-large;
  }

  /* type */

  &.primary {
    background-color: $dark-color;
    color: $light-color;

    &:hover {
      background-color: $dark-color-hover;
    }

    &.darkMode {
      background-color: $light-color;
      color: $dark-color;

      &:hover {
        background-color: $light-color-hover;
      }
    }

    &.darkOnDark {
      background-color: $grey-9;
      color: $body-color-dark;
      border: 1px solid $grey-7;

      &:hover {
        background-color: $grey-10;
      }
    }

    &.lightOnDark {
      background-color: $grey-8;

      &:hover {
        background-color: $grey-7;
      }
    }
  }

  &.secondary {
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background-color: rgba(255, 255, 255, 0.2);

    &.darkMode {
      color: $light-color;
      border-color: 1px solid rgba(255, 255, 255, 0.05);

      &:hover {
        opacity: 0.9;
        // background-color: $dark-color-hover;
      }
    }

    // account for border so height is same as other types
    &.small {
      padding: $p-vertical-small - 1 $p-horizontal-small;
    }

    &.medium {
      padding: $p-vertical-medium - 1 $p-horizontal-medium;
    }

    &.large {
      padding: $p-vertical-large - 1 $p-horizontal-large;
    }

    &.x-large {
      padding: $p-vertical-x-large - 1 $p-horizontal-x-large;
    }
  }

  &.tertiary {
    color: $dark-color;
    background-color: $grey-3;

    &.darkMode {
      color: $light-color;
      background-color: $grey-8;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &.borderless {
    border: none !important;
    background-color: inherit !important;
    padding: 0 !important;
  }

  &.fluid {
    width: 100%;
  }

  &.googleBrand {
    background-color: white;
    color: black;

    &:hover {
      opacity: 0.95;
    }

    img {
      width: 20px;
    }
  }
}
