@import '../../scss/colors.scss';

.card {
  border: 1px solid $grey-4;
  border-radius: 4px;
  padding: 16px 24px 24px;

  &.darkMode {
    border-radius: 16px;
    border-color: $grey-9;
    background-color: $grey-10;
    padding: 16px 24px;
  }
}

@media (max-width: 800px) {
  .card {
    padding: 16px;
  }
}
